textarea
{
   resize: none;
}

.container-form{
	margin-right: 10%;
}

.formulario {
	width: 600px;
	background: rgb(65, 65, 65);
	border-radius: 5px;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.formulario .exito {
	text-align: center;
	font-size: 14px;
	padding-top: 20px;
	color: #02a802;
}

.formulario > div {
	margin-bottom: 20px;
}

.formulario label {
	display: block;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 5px;
	color: #1f1f1f;
}

.formulario input,
.formulario textarea {
	font-family: 'Open Sans', sans-serif;
	width: 100%;
	border-radius: 5px;
	border: 2px solid #e2e2e2;
	font-size: 18px;
	padding: 10px;
	margin-bottom: 5px;
	color: #1f1f1f;
	background-color: rgb(202, 202, 202);
}

.formulario input:focus {
	outline: none;
	border: 2px solid #0085FF;
}

.formulario .error {
	background-color: rgba(68, 115, 196);
	font-weight: 600;
	font-size: 12px;
}

.formulario button[type="submit"] {
	display: block;
	background-color: rgba(68, 115, 196);
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
	border: none;
	cursor: pointer;
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	transition: .3s ease all;
}

.formulario button[type="submit"]:hover {
	background-color: rgb(187, 40, 40);
}

.formulario .exito {
	text-align: center;
	font-size: 14px;
	padding-top: 20px;
	color: #02a802;
}

.formulario input[type="radio"] {
	width: auto;
}



@media only screen and (max-width: 1500px) {
	.container-form {
		margin-right: 10%;
		margin-left: 10%;
	  justify-content: center;
	  align-items: center;
	  display: flex;
	}
	.formulario{
		width: 600px;
		align-items: center;
	}
}