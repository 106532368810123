.footer {
  width: 100%;
  height: 40px;
  background-color: rgba(68, 115, 196);
  transition: all 4s ease;
  color: black;
  position: absolute;
}

.footer-info {
  font-size: 35px;
  padding: 30px 0 30px 50px;
  width: 70%;
  margin: 0 auto;
}

.footer-info > h1 {
  padding-bottom: 15px;
}

.footer-contact {
  font-size: 22px;
  padding: 100px 0 30px 50px;
  width: 70%;
  margin: 0 auto;
}

.footer-sns {
  width: 70%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
}

.sns-links {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
}

.linkedin,
.instagram,
.facebook {
  color: black;
  font-size: 35px;
  margin: 0 auto;
  border-bottom: 4px solid transparent;
}

.linkedin:hover,
.instagram:hover,
.facebook:hover {
  border-color: black;
  transition: all 0.3s ease-out;
}

.design-by {
  font-size: 18px;
  color: black;
  transition: 300ms ease-in;
}

.design-by > p {
  font-size: 18px;
  color: black;
  transition: 300ms ease-in;
}

.design-by:hover {
  color: white;
  cursor: default;
  transition: 300ms ease-in-out;
}


@media only screen and (max-width: 960px) {
  .footer{
    height: 68px;
  }
  .footer-info {
    text-align: center;
    padding: 0;
    font-size: 20px;
  }

  .footer-contact {
    font-size: 16px;
    padding: 100px 0 30px 0;
    text-align: center;
  }

  .footer-sns {
    grid-template-columns: 2fr;
    text-align: center;
  }

  .design-by {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 5px;
  }
}
