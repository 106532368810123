.navbar {
  position: fixed;
  top: 0;
  background-color: transparent;
  width: 100%;
  height: 60px;
  padding-left: 50px;
  font-size: 20px;
  z-index: 1;
}

.scrolling {
  background-color: rgba(68, 115, 196);
  transition: all 4s ease;
}

.visibility{
  visibility: visible!important;
  transform: translateX(90%);
  transition: all 2s ease-out;
}

.navbar-logo {
  height: 80px;
  line-height: 80px;
  color: white;
  cursor: pointer;
  visibility: hidden;
}

.logo {
  margin-top: 10px;
  max-height: 120px;
  max-width: 140px;
  transition: all 1s ease-in;
}

.logo:hover {
  transform: scale(1.1);
  transition: all 1s ease-out;
}

@media screen and (max-width: 1500px) {
  .visibility{
    visibility: visible!important;
    transform: translateX(-5%);
    align-items: center;
    align-content: center;
    text-align: center;
    transition: all 3s ease-out;
  }
}