.info-container {
  width: 100%;
  height: 200px;
  background-color: rgb(65, 65, 65);
  color: rgb(202, 202, 202);
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.info h1{
  transform: scale(2);
  transition: all 10s ease;
  cursor: default;
}

@media screen and (max-width: 1500px) {
  .info h1{
    transform: scale(1);
    transition: all 3s ease;
  }
}

@media only screen and (max-width: 960px) {
  .info-container {
    height: 200px;
    width: 100%;
  }

  .info > h1 {
    text-align: center;
    font-size: 25px;
  }
}
