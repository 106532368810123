.contact {
    width: 100%;
    height: 70%;
    background-color: #000000;
    color: rgb(202, 202, 202);
    display: flex;
    z-index: -2;
    left: 0;
	  padding-top: 75px;
    padding-bottom: 75px;
  }
  
  .contact-info {
    font-size: 35px;
    padding-top: 50px;
    width: 70%;
  }
  
  .contact-info > h1 {
    padding-bottom: 15px;
    text-align: center;
    cursor: default;
  }
  
  .contact-info > p {
    text-align: center;
    cursor: default;
  }


  
@media only screen and (max-width: 1500px) {
  .contact {
    flex-direction: column;
    padding-top: 20px;
  }

  .contact-info {
width: auto;
padding-top: 0px;
padding-bottom: 20px;

  }

  
}